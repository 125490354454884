import React from 'react';
import Layout from './Layout'

const Layouts = () => {
    return (
        <div className='layouts__container'>
            <Layout></Layout>
            <Layout></Layout>
            <Layout></Layout>
        </div>
    );
};

export default Layouts;